import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import ClosedMail from "../Assets/closed-mail.png";
import petal from "../Assets/petale-rose.png"

const appear = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 89vh;
  position: relative;
  overflow: hidden;
`;

const Envelope = styled.div`
  width: 350px;
  height: 250px;
  background-image: url(${ClosedMail});
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
`;

const Button = styled.button`
  background-color: #fff;
  color: #ff69b4;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 25px;
    top: 2px;
    left: 2px;
    z-index: -1;
    transition: all 0.3s ease-out;
  }

  &:active {
    top: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    
    &:after {
      top: 0;
      left: 0;
    }
  }

  &:hover {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);

    &:after {
      top: -2px;
      left: -2px;
    }
  }
`;

const RoseBackground = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url(${petal}); // Use a small rose image
  animation: ${appear} 5s infinite alternate;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  opacity: 0;
`;


function HomePage() {
  const navigate = useNavigate();
  return (
    <Container>
      {Array.from({ length: 20 }).map((_, index) => (
        <RoseBackground
          key={index}
          top={Math.random() * 100}
          left={Math.random() * 100}
        />
      ))}
        <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{display:'flex', justifyContent:'center', textAlign:'center', color:'#3d3d3d'}}>
                <h2>Pour Mag 🧿</h2>
            </div>
            <Envelope/>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Button onClick={() => navigate('/message')}>Ouvrir mon invitation</Button>
            </div>
        </div>
    </Container>
  );
}

export default HomePage;
