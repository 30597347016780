import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Component/HomePage';
import MessagePage from './Component/MessagePage';
import ConfirmationPage from './Component/ConfirmationPage';
import Header from './Component/Header';
import Footer from './Component/Footer';
import styled from 'styled-components';
import emailjs from 'emailjs-com';

const Container = styled.div`
  height: 100vh;
  background-color: pink;
  position: relative;
  overflow: hidden;
`;

function App() {
  useEffect(() => {
    // Fonction pour envoyer un e-mail à l'ouverture de la page
    const sendEmailOnPageLoad = async () => {
      try {
        const date = new Date();
        const day = date.toLocaleDateString('fr-FR', { weekday: 'long' });
        const time = date.toLocaleTimeString('fr-FR');
        await emailjs.send('service_ap3bohn', 'template_hgq14wc', {
          to_email: 'garysaah@gmail.com',
          subject: 'Ouverture de la page',
          text: `La page a été ouverte le ${day} à ${time}`,
        }, '0ef5DhLb4EDWp6LHf');
        console.log('Email sent successfully');
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };

    // Appeler la fonction d'envoi d'e-mail à l'ouverture de la page
    sendEmailOnPageLoad();
  }, []); // Le tableau vide indique que cette fonction d'effet ne dépend d'aucune valeur, donc elle est exécutée une seule fois lors du rendu initial.

  return (
    <Container>
      <Header />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/message" element={<MessagePage />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
        </Routes>
      </Router>
      <Footer />
    </Container>
  );
}

export default App;

