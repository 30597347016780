import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 89vh;
  position: relative;
  overflow: hidden;
`;

const Card = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
`;

function ConfirmationPage() {
  return (
    <Container>
      <Card>
        <h1>Allezzz !</h1>
        <p>J'étais presque sûre que tu accepterais !</p>
        <p style={{fontWeight:500}}>Retrouvons nous <br /> <span style={{fontWeight:'800'}}>Jeudi soir</span> à <span style={{fontWeight:'800'}}>21h</span> <br /> au <br /> <span style={{fontWeight:'800'}}>13 Rue Montalivet, 75008</span>.</p>
      </Card>
    </Container>
  );
}

export default ConfirmationPage;
