import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Papper from "../Assets/paper-mail.png";
import MailClosed from "../Assets/closed-mail.png";
import emailjs from 'emailjs-com';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 89vh;
  position: relative;
  overflow: hidden;
`;

const EnvelopeAndCardWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Envelope = styled.img`
  width: 350px;
  height: 250px;
  transition: opacity 1s ease-in-out;
  z-index: 10;
`;

const openEnvelopeAnim = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(0); }
`;

const Card = styled.img`
  width: 350px;
  height: 250px;
  animation: ${openEnvelopeAnim} 1s ease-in forwards;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`;


const CloudDiv = styled.div`
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  text-align: center;
  padding: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

  &:before,
  &:after {
    content: '';
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
  }

  &:before {
    width: 150px;
    height: 150px;
    top: -75px;
    left: 50px;
  }

  &:after {
    width: 100px;
    height: 100px;
    top: -50px;
    right: 50px;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: #ff69b4;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-out;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 25px;
    top: 2px;
    left: 2px;
    z-index: -1;
    transition: all 0.3s ease-out;
  }

  &:active {
    top: 2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    
    &:after {
      top: 0;
      left: 0;
    }
  }

  &:hover {
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);

    &:after {
      top: -2px;
      left: -2px;
    }
  }
`;


function MessagePage() {
    const navigate = useNavigate();
  
    const sendEmailAutomatically = async (withFlowers = false) => {   
        emailjs.send('service_ap3bohn', 'template_91y0fpr',{
            to_email: 'gary.h@dikio.fr',
            subject: "Elle a dit oui !!",
            text: `Magalie a accepté ton rendez-vous${withFlowers ? " avec des fleurs" : ""}`
        },'0ef5DhLb4EDWp6LHf')
    .then(function(response) {
        console.log('Email sent successfully');
        navigate('/confirmation')
    }, function(error) {
        console.error('Error sending email:', error);
    });
};
    
  
    return (
        <Container>
            <EnvelopeAndCardWrapper>
                <Envelope src={MailClosed} alt="Closed Envelope" />
                <Card src={Papper} alt="Paper Card" />
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <Button onClick={() => sendEmailAutomatically()}>J'accepte</Button>
                <Button onClick={() => sendEmailAutomatically(true)}>J'accepte avec des fleurs</Button>
                </div>
            </EnvelopeAndCardWrapper>
        </Container>
    );
  }
  
  export default MessagePage;
