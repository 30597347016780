import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
display: flex;
position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #666;
  margin-top: -45px;
  width: 100%;
  @media (max-width: 768px) {

  margin-top: -45px;
  }
`;

const FooterText = styled.p`
  text-align: center;
  margin: 0;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterText>© {new Date().getFullYear()} Made with ❤️ by <span style={{color:'green', fontWeight:'500'}} >Dr.Haas</span></FooterText>
    </FooterContainer>
  );
}

export default Footer;