import React from 'react';
import styled from 'styled-components';
import logoImage from '../Assets/rose-logo.png'; // Assurez-vous que le chemin vers votre image de logo est correct.

const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #ececec;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  height: 50px; // Ou la taille que vous souhaitez pour votre logo.
`;


const SendMessageButton = styled.a` // Changé en élément 'a' pour le lien
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background-color: #ff69b4;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none; // Supprime le soulignement par défaut des liens
  &:hover {
    background-color: #e5559e;
  }
`;

function Header() {
  // Le numéro de téléphone et le message doivent être URL-encodés si nécessaire
  const phoneNumber = '+33650507317'; 
  const defaultMessage = '❤️🌈'; 

  // Construction du lien SMS
  const smsLink = `sms:${phoneNumber}?&body=${encodeURIComponent(defaultMessage)}`;

  return (
    <HeaderContainer>
      <Logo src={logoImage} alt="Logo" />
      <SendMessageButton href={smsLink}>
        Envoyer un message
      </SendMessageButton>
    </HeaderContainer>
  );
}

export default Header;